<!-- src/components/RegisterForm.vue -->
<template>
    <div class="register-container">
      <h2>Create Account</h2>
      <form @submit.prevent="registerUser" class="register-form">
        <div class="form-group">
          <label for="username">Username</label>
          <input
            v-model="username"
            type="text"
            id="username"
            class="input-field"
            required
          />
        </div>
        <div class="form-group">
          <label for="email">Email</label>
          <input
            v-model="email"
            type="email"
            id="email"
            class="input-field"
            required
          />
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input
            v-model="password"
            type="password"
            id="password"
            class="input-field"
            required
          />
        </div>
        <button type="submit" class="submit-button">Create Account</button>
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
        <p v-if="successMessage" class="success-message">{{ successMessage }}</p>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    metaInfo: {
    title: 'Login',
    meta: [
      { name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' }
    ]
  },
    data() {
      return {
        username: '',
        email: '',
        password: '',
        errorMessage: '',
        successMessage: '',
      };
    },
    methods: {
      async registerUser() {
        try {
          await axios.post('https://api.6buoys.com/api/create-account/', {
            username: this.username,
            email: this.email,
            password: this.password
          });
  
          this.successMessage = 'Account created successfully!';
          this.errorMessage = '';
  
          // Redirect to login or home page after a delay
          setTimeout(() => {
            this.$router.push('/login');
          }, 2000);
        } catch (error) {
          this.errorMessage = error.response.data.error || 'Failed to create account.';
          this.successMessage = '';
        }
      }
    }
  };
  </script>
  
  
  <style scoped>
  .register-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #192734;
    border-radius: 16px;
    color: #d9d9d9;
  }
  
  h2 {
    text-align: center;
    color: #1da1f2;
    margin-bottom: 20px;
  }
  
  .register-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  label {
    color: #8899a6;
    margin-bottom: 5px;
  }
  
  .input-field {
    padding: 12px;
    background-color: #15202b;
    border: 1px solid #38444d;
    border-radius: 10px;
    color: #d9d9d9;
  }
  
  .input-field::placeholder {
    color: #8899a6;
  }
  
  .submit-button {
    padding: 12px 20px;
    background-color: #1da1f2;
    border: none;
    border-radius: 10px;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover {
    background-color: #0c8ddb;
  }
  
  .error-message {
    color: #e0245e;
    text-align: center;
    margin-top: 10px;
  }
  
  .success-message {
    color: #1da1f2;
    text-align: center;
    margin-top: 10px;
  }
  </style>
  