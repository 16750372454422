<template>
    <div>
      <!-- Back link section -->
      <div class="back-link-section">
        <a href="/" class="back-link">
          <span class="arrow">&#8592;</span> <!-- Unicode left arrow -->
          <span class="back-link-text">Go to the waterski timeline</span>
        </a>
      </div>
  
      <div class="profile-container">
        <div class="profile-header">
          <img :src="profile.avatar || defaultAvatar" alt="Profile Picture" class="profile-avatar" />
          <div class="profile-info">
            <h2>{{ profile.username }}</h2>
            <p>{{ profile.first_name }} {{ profile.last_name }}</p>
            <p class="bio">{{ profile.bio }}</p>
          </div>
        </div>
    
        <div class="profile-stats">
          <div class="stat">
            <span>{{ profile.posts_count }}</span>
            <p>Posts</p>
          </div>
          <div class="stat">
            <span>{{ profile.followers_count }}</span>
            <p>Followers</p>
          </div>
          <div class="stat">
            <span>{{ profile.following_count }}</span>
            <p>Following</p>
          </div>
        </div>
    
        <form @submit.prevent="updateProfile" class="profile-form">
          <div class="form-group">
            <label for="first_name">First Name</label>
            <input type="text" v-model="profile.first_name" id="first_name" class="input-field" />
          </div>
    
          <div class="form-group">
            <label for="last_name">Last Name</label>
            <input type="text" v-model="profile.last_name" id="last_name" class="input-field" />
          </div>
    
          <div class="form-group">
            <label for="bio">Bio</label>
            <textarea v-model="profile.bio" id="bio" class="input-field textarea-field"></textarea>
          </div>
    
          <div class="form-group">
            <label for="ski">Ski</label>
            <input type="text" v-model="profile.ski" id="ski" class="input-field" />
          </div>
    
          <div class="form-group">
            <label for="lake">Lake</label>
            <input type="text" v-model="profile.lake" id="lake" class="input-field" />
          </div>
    
          <div class="form-group">
            <label for="pb">Personal Best</label>
            <input type="text" v-model="profile.PB" id="pb" class="input-field" />
          </div>
    
          <div class="form-group">
            <label for="avatar">Profile Picture</label>
            <input type="file" @change="onFileChange" id="avatar" class="input-field" />
          </div>
    
          <button type="submit" class="submit-button">Update Profile</button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    metaInfo: {
    title: 'Login',
    meta: [
      { name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' }
    ]
    },
    data() {
      return {
        profile: {
          first_name: '',
          last_name: '',
          bio: '',
          ski: '',
          lake: '',
          PB: '',
          avatar: '',
          username: '',
          posts_count: 0,
          followers_count: 0,
          following_count: 0,
          user_id: null, // Ensure user_id is correctly retrieved
        },
        defaultAvatar: '/path/to/default/avatar.png',
      };
    },
    async created() {
      await this.fetchProfile();
    },
    methods: {
      async fetchProfile() {
        try {
          const response = await axios.get('https://api.6buoys.com/api/profile/', {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
          });
  
          console.log('API response:', response.data); // Log the entire API response
  
          this.profile = response.data;
          this.profile.user_id = response.data.id; // Ensure user_id is set correctly
          console.log('Assigned user_id:', this.profile.user_id); // Log the assigned user_id
  
        } catch (error) {
          console.error('Error fetching profile:', error);
        }
      },
      async updateProfile() {
        try {
          const formData = new FormData();
          formData.append('first_name', this.profile.first_name);
          formData.append('last_name', this.profile.last_name);
          formData.append('bio', this.profile.bio);
          formData.append('ski', this.profile.ski);
          formData.append('lake', this.profile.lake);
          formData.append('PB', this.profile.PB);
          if (this.profile.avatar instanceof File) {
            formData.append('avatar', this.profile.avatar);
          }
  
          // Make sure the user ID is used correctly in the API call
          await axios.put(`https://api.6buoys.com/api/profile/${this.profile.user_id}/`, formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
              'Content-Type': 'multipart/form-data',
            },
          });
          alert('Profile updated successfully!');
        } catch (error) {
          console.error('Error updating profile:', error);
        }
      },
      onFileChange(e) {
        const file = e.target.files[0];
        this.profile.avatar = file;
      },
    },
  };
  </script>
  
  <style scoped>
  .back-link-section {
    padding: 10px;
    text-align: center;
  }
  
  .back-link {
    color: #ffffff; /* White color for text */
    text-decoration: none; /* Remove underline from the link */
    font-weight: bold;
  }
  
  .back-link:hover {
    text-decoration: underline; /* Underline text on hover */
  }
  
  .arrow {
    font-size: 1.5em;
    margin-right: 8px; /* Space between arrow and text */
    vertical-align: middle; /* Align arrow vertically with text */
  }
  
  .profile-container {
    max-width: 500px;
    margin: 0px auto; /* Add margin at the top for spacing */
    padding: 20px;
    background-color: #192734;
    border-radius: 16px;
    color: #d9d9d9;
  }
  
  .profile-header {
    display: flex;
    align-items: flex-start; /* Align items to the start */
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .profile-avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .profile-info {
    flex: 1; /* Allow bio and name to take up available space */
    text-align: left;
  }
  
  .profile-info h2 {
    margin: 0;
    color: #1da1f2;
  }
  
  .profile-info p {
    margin: 2px 0; /* Reduce the margin between the name and username */
  }
  
  .bio {
    word-wrap: break-word; /* Ensure long bios don't push elements to the right */
    margin-top: 5px;
  }
  
  .profile-stats {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .stat {
    text-align: center;
  }
  
  .stat span {
    display: block;
    font-size: 1.5em;
    color: #1da1f2;
  }
  
  .stat p {
    margin: 0;
    color: #8899a6;
  }
  
  .profile-form .form-group {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column; /* Ensure labels and input fields stack vertically */
    align-items: flex-start; /* Align labels to the start of the input fields */
  }
  
  .form-group label {
    margin-bottom: 5px; /* Add space between label and input field */
    font-weight: bold;
    color: #d9d9d9;
  }
  
  .input-field {
    width: 96%; /* Make input fields span the width of the form-group */
    padding: 10px;
    background-color: #15202b;
    border: 1px solid #38444d;
    border-radius: 8px;
    color: #d9d9d9;
  }
  
  .textarea-field {
    resize: vertical; /* Allow textarea to resize vertically */
    min-height: 60px;
  }
  
  .input-field::placeholder {
    color: #8899a6;
  }
  
  .submit-button {
    width: 100%;
    padding: 12px;
    background-color: #1da1f2;
    border: none;
    border-radius: 10px;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover {
    background-color: #0c8ddb;
  }
  </style>
  