import { createRouter, createWebHistory } from 'vue-router';
import PublicHome from '@/components/PublicHome.vue';
import HomePage from '@/components/HomePage.vue';
import LoginForm from '@/components/LoginForm.vue';
import PostCreate from '@/components/PostCreate.vue';
import RegisterForm from '@/components/RegisterForm.vue';
import MyProfile from '@/components/MyProfile.vue';
import AllSkiers from '@/components/AllSkiers.vue';
import PrivacyPolicy from '@/components/PrivacyPolicy.vue';


const routes = [
  {
    path: '/',
    name: 'PublicHome',
    component: PublicHome
  },
  {
    path: '/timeline',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginForm,
  },
  {
    path: '/post/create',
    name: 'PostCreate',
    component: PostCreate,
  },
  {
    path: '/create-account',
    name: 'Register',
    component: RegisterForm,
  },
  {
    path: '/my-profile',
    name: 'MyProfile',
    component: MyProfile,
  },
  {
    path: '/all-skiers',
    name: 'AllSkiers',
    component: AllSkiers,
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
