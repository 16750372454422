<template>
    <div class="skiers-container">
      <h1 class="title">All Skiers</h1>
      
      <LoadingSpinner v-if="isLoading" />
  
      <div v-if="!isLoading && skiers.length" class="skiers-list">
        <div v-for="skier in skiers" :key="skier.id" class="skier-card">
          <img :src="skier.avatar || defaultAvatar" alt="Profile Picture" class="skier-avatar" />
          <div class="skier-info">
            <h2 class="skier-username">
              {{ skier.username }}
              <img
                v-if="skier.verified"
                src="@/assets/verified.png"
                alt="Verified"
                class="verified-badge"
                title="Verified Skier"
              />
            </h2>
            <p class="skier-bio">{{ formatBio(skier.bio) }}</p>
          </div>
        </div>
      </div>
  
      <div v-else-if="!isLoading">
        <p>No skiers found.</p>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import LoadingSpinner from '@/components/LoadingSpinner.vue';
  
  export default {
    components: {
      LoadingSpinner,
    },
    data() {
      return {
        skiers: [],
        defaultAvatar: '/path/to/default/avatar.png', // Set path to default avatar
        isLoading: true, // Add a loading state
      };
    },
    async created() {
      await this.fetchSkiers();
    },
    methods: {
      async fetchSkiers() {
        try {
          const response = await axios.get('https://api.6buoys.com/api/profiles/', {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
          });
          this.skiers = response.data;
        } catch (error) {
          console.error('Error fetching skiers:', error);
        } finally {
          this.isLoading = false; // Ensure loading is set to false after fetch
        }
      },
      formatBio(bio) {
        return bio.length > 140 ? bio.substring(0, 140) + '...' : bio;
      },
    },
  };
  </script>
  
  <style scoped>
  .skiers-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #192734;
    border-radius: 16px;
    color: #d9d9d9;
  }
  
  .title {
    text-align: center;
    color: #1da1f2;
    margin-bottom: 20px;
  }
  
  .skiers-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .skier-card {
    display: flex;
    background-color: #15202b;
    padding: 15px;
    border-radius: 12px;
    width: 100%;
    max-width: 350px;
    align-items: flex-start; /* Align items to the top */
    gap: 15px;
    overflow: hidden; /* Prevent content from overflowing */
  }
  
  .skier-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%; /* Make the image round */
    object-fit: cover;  /* Ensure the image fills the circle */
    border: 2px solid #1da1f2;
    flex-shrink: 0; /* Prevent the image from shrinking */
  }
  
  .skier-info {
    flex-grow: 1;
    min-width: 0; /* Prevent bio from expanding the container */
  }
  
  .skier-username {
    margin: 0;
    font-size: 1.2em;
    color: #ffffff;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .verified-badge {
    width: 18px;
    height: 18px;
  }
  
  .skier-bio {
    margin: 5px 0 0;
    color: #8899a6;
    font-size: 0.9em;
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflow content */
    text-overflow: ellipsis; /* Display ellipsis (...) for overflow */
  }
  </style>
  